<template>
  <Card class="purchase-confirmation" theme="border">
    <template #header>
      <h1>{{ $t('documentSigned') }}</h1>
    </template>
    <template>
      <Icon :icon="['fad', 'check-circle']" size="2x" />
      <div class="purchase-confirmation__message">{{ $t('documentSignedConfirmationMessage') }}</div>
    </template>
  </Card>
</template>

<script>
import { i18n } from '@/i18n'
import { mapState } from 'vuex'
import Card from '@/components/Card'

const meta = {
  order: 2,
  id: 'confirmation',
  label: i18n.tc('documentSigned'),
  icon: ['fal', 'file-alt'],
}

export { meta }
export default {
  props: {
    value: Object,
    labels: Object,
    validator: Object,
  },
  computed: {
    ...mapState('Contract', ['item']),
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.purchase-confirmation {
  text-align: center;

  .card__header {
    min-height: 125px;
  }

  &__message {
    max-width: 400px;
    line-height: 1.5;
    margin: 1rem auto 2rem;
  }

  h1 {
    margin: 0;
  }
}
</style>
