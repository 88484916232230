const requireModule = require.context('.', true, /\.vue$/)

const data = requireModule
  .keys()
  .sort()
  .filter(fileName => {
    if (fileName.endsWith('index.js')) return false
    return true
  })

export default function () {
  return data
    .reduce((res, fileName) => {
      const module = requireModule(fileName)
      if (module.meta.skip) return res

      res.push({
        order: module.meta.order,
        component: module.default,
        id: module.meta.id,
        label: module.meta.label,
        icon: module.meta.icon,
        labels: module.labels,
        rules: module.rules,
      })

      return res
    }, [])
    .sort((a, b) => a.order - b.order)
}
